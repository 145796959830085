import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const fabric = window.fabric

class DesignCanvas extends React.Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    downloadFile: PropTypes.any,
  }

  static defaultProps = {
    width: 1000,
    height: 550,
  }

  state = {
    canvas: null,
  }

  componentDidMount() {
    const canvas = new fabric.Canvas(this.c)
    this.setState({ canvas })
  }

  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        canvas: this.state.canvas,
      })
    })
    const { width, height } = this.props
    return (
      <Fragment>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <canvas id="certificate" ref={c => (this.c = c)} width={width} height={height} />
          {this.state.canvas && children}
        </div>
      </Fragment>
    )
  }
}

export default DesignCanvas
