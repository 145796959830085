import React, { useState } from "react";
import { UserCertificate } from "../../components";

//service
import { getCertificate } from "../../services/download.js";
function CertificatePage() {
  const [canvasControl, setCanvasControl] = React.useState({
    isLoading: true,
    userName: "",
    url: "",
    namePositionHeigth: 0,
    namePositionWidth: 0,
    fontSize: 30,
    fontColor: "",
    nameAlign: "",
    idEvent: 0,
    idPart: 0,
    idCertificate: 0,
    native: false,
  });
  const [requestApi, setRequestApi] = useState(true);

  React.useEffect(() => {
    getPageData();
  }, []);

  React.useEffect(() => {
    getCertificateData();
  }, [canvasControl]);

  async function getCertificateData() {
    if (requestApi) {
      await getCertificate(canvasControl.idCertificate)
        .then((response) => {
          console.log(response);
          setCanvasControl((state) => {
            return {
              ...state,
              url: response.data.image,
              fontColor: response.data.color,
              fontSize: response.data.fontSize,
              namePositionHeigth: response.data.heightPosition,
              namePositionWidth: response.data.widthPosition,
              nameAlign: response.data.textAlignment,
              isLoading: false,
            };
          });
        })
        .catch((error) => console.log(error));
      setRequestApi(false);
    }
  }

  const getPageData = async () => {
    try {
      let _canvasControl = { ...canvasControl };
      _canvasControl = getParamsFromPath();

      // _canvasControl.isLoading = false;
      setCanvasControl(_canvasControl);
    } catch (error) {
      console.log("Error getPageData", error);
    }

    function getParamsFromPath() {
      try {
        let _params = window.location.toString()?.split("?")[1]?.split("&");
        let _config = { ...canvasControl };
        _params?.forEach((item) => {
          if (item.includes("idEvent=")) {
            _config.idEvent = item.replace("idEvent=", "");
          }
          if (item.includes("idPart=")) {
            _config.idPart = item.replace("idPart=", "");
          }
          if (item.includes("idCertificate=")) {
            _config.idCertificate = item.replace("idCertificate=", "");
          }
          if (item.includes("namePart=")) {
            _config.userName = decodeURI(item.replace("namePart=", ""));
          }
          if (item.includes("native=")) {
            _config.native = true;
          }
        });
        _config.url = canvasControl.url;
        return _config;
      } catch (error) {
        console.log("Error getParamsFromPath", error);
        throw error;
      }
    }
  };

  if (canvasControl.isLoading) {
    return <div>Carregando.</div>;
  }
  return (
    <>
      {canvasControl.url && (
        <UserCertificate
          userName={canvasControl.userName}
          image={canvasControl.url}
          namePositionHeigth={canvasControl.namePositionHeigth}
          namePositionWidth={canvasControl.namePositionWidth}
          fontSize={canvasControl.fontSize}
          fontColor={canvasControl.fontColor}
          nameAlign={canvasControl.nameAlign}
          canvas={canvasControl}
        />
      )}
    </>
  );
}

export default CertificatePage;
