import React from 'react';
import Button from '@mui/material/Button';

const ButtonProp = props => {
    const {
        children,
        text = "",
        color = "#3f51b5",
        textColor = 'white',
        size = null, //small, medium, large
        onClick,
        height = "auto",
        borderRadius = null
    } = props;

    var styles = {
        button: {
            backgroundColor: color,
            color: textColor,
            height: height,
            borderRadius: borderRadius
        }
    };

    return (
        <Button
            style={styles.button}
            variant="contained"
            size={size ? size : "medium"}
            onClick={onClick}
        >
            {text && text !== "" ? text : children}
        </Button>
    )
};

export default ButtonProp;
