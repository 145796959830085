

const returnObject = {
  getUrl() {
    return {
      corsAnywhere: "https://allow-any-origin-iframe.sistemasinteegra.com.br/",
    }
  }
}

export default returnObject;
