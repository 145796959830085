import React from 'react';

const FormRow = props => {
    const {
        children,
        onClick,
        align = null,
        background = null,
        padding = null,
        paddingTop = '0px',
        paddingBottom = '0px',
        paddingLeft = '0px',
        paddingRight = '0px',
        alignItems = null,
        margin = "5px",
        height = "auto",
        minHeight = "auto",
        overflow = "hidden",
        direction = "row",
        width = "100%",
        cursor = null,
    } = props;

    const styles = {
        container: {
            backgroundColor: background ? background : 'transparent',
            marginTop: margin,
            marginBottom: margin,
            display: 'flex',
            flexDirection: direction ? direction : 'row',
            flexWrap: 'wrap',
            width: width,
            paddingTop: padding ? padding : paddingTop,
            paddingBottom: padding ? padding : paddingBottom,
            paddingLeft: padding ? padding : paddingLeft,
            paddingRight: padding ? padding : paddingRight,
            justifyContent: align ? align : 'left',
            alignItems: alignItems ? alignItems : 'left',
            textAlign: align ? align : 'left',
            height: height,
            minHeight: minHeight,
            overflow: overflow,
            cursor: cursor,
        }
    };

    return (
        <div
            onClick={onClick}
            style={
                styles.container
            }
        >
            {children}
        </div>
    )
};

export default FormRow;
