import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

const Component = props => {
    const {
        children,
        open,
        onClose,
        title = "",
        disableBackdropClick = false,
        onClickYes,
        onClickNo,
        textButtonYes = 'Sim',
        textButtonNo = 'Não',
        fullScreen = false,
        disableNoButton = false,
    } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            disableBackdropClick={disableBackdropClick}
            scroll={"body"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullScreen={fullScreen}
            style={{
                zIndex: 12
            }}
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            {onClickYes || onClickNo ? (
                <DialogActions>
                    {!disableNoButton && (
                        <Button onClick={onClickNo} color="primary">
                            {textButtonNo}
                        </Button>
                    )}
                    <Button onClick={onClickYes} color="primary" autoFocus>
                        {textButtonYes}
                    </Button>
                </DialogActions>
            ) : (null)}

        </Dialog>
    )
};

export default Component;
