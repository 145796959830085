import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const ActivityIndicator = props => {
    const { color = null } = props;

    return (
        <CircularProgress
            color={color ? color : "primary"}
        />
    )
};

export default ActivityIndicator;
