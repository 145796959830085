import axios from "axios";

const urlBase = "https://gateway-admin-plus.sistemasinteegra.com.br/api-admin-certificado";

function postCertificate(eventId, logintId, certificateId) {
  var data = JSON.stringify({
    eventId,
    logintId,
    certificateId
  });

  var config = {
    method: "post",
    url: `${urlBase}/certificate-download`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getCertificate(id) {
  var config = {
    method: "get",
    url: `${urlBase}/certificates/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export { postCertificate, getCertificate };
