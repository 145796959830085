import React from "react";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import InputAdornment from "@mui/material/InputAdornment";
import { LocalizationProvider, DateTimePicker, DatePicker, TimePicker } from '@mui/lab';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

const InputFieldOutlined = props => {
  const {
    key,
    value,
    title,
    placeholder = "",
    onChange,
    onKeyPress,
    onFocus,
    error = false,
    errorMessage = "",
    required = false,
    maxLength = -1,
    mask = "",
    backgroundColor = "white",
    disabled = false,
    onBlur,
    autoFocus = false,
    inputRef,
    startAdornment,
    endAdornment,
    type = "text",
    multiline = false,
    onClick,
  } = props;

  const [color, setColor] = React.useState("#3f51b5");
  const [borderRadius, setBorderRadius] = React.useState("5px");
  const [useTitleAsPlaceholder, setUseTitleAsPlaceholder] = React.useState(false);

  const onChangeDate = (date) => {
    var _date = {
      target: {
        value: moment(date).format()
      }
    };
    props.onChange(_date);
  }

  const styles = {
    backgroundColor: backgroundColor,
    borderRadius: borderRadius,
    width: "100%",
  };

  if (mask && mask != "" && !disabled) {
    return (
      <InputMask
        mask={mask}
        value={value}
        onChange={onChange}
        maskChar=""
        onBlur={onBlur}
        onFocus={onFocus}
      >
        {() => (
          <TextField
            style={styles}
            InputProps={{
              maxLength: maxLength,
              startAdornment: startAdornment ? (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ) : null,
              endAdornment: endAdornment ? (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ) : null,
              style: {
                borderRadius: borderRadius
              }
            }}
            required={required}
            error={error}
            helperText={errorMessage}
            key={key}
            label={!useTitleAsPlaceholder ? title : null}
            placeholder={useTitleAsPlaceholder ? title : placeholder}
            variant="outlined"
            fullWidth
            onKeyPress={onKeyPress}
            autoFocus={autoFocus}
            inputRef={inputRef}
            type={type}
            multiline={multiline}
            onClick={onClick}
          />
        )}
      </InputMask>
    );
  } else if (type === "datetime-local") {
    return (
      <LocalizationProvider utils={DateFnsUtils}>
        <DateTimePicker
          style={styles}
          inputVariant="outlined"
          value={value}
          onChange={e => {
            onChangeDate(e);
          }}
          required={required}
          error={error}
          helperText={errorMessage}
          key={key}
          label={!useTitleAsPlaceholder ? title : null}
          placeholder={useTitleAsPlaceholder ? title : placeholder}
          onKeyPress={onKeyPress}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          autoFocus={autoFocus}
          inputRef={inputRef}
          format="dd/MM/yyyy - HH:mm"
          InputProps={{
            style: {
              borderRadius: borderRadius
            }
          }}
          onClick={onClick}
        />
      </LocalizationProvider>
    );
  } else if (type === "date") {
    return (
      <LocalizationProvider utils={DateFnsUtils}>
        <DatePicker
          style={styles}
          inputVariant="outlined"
          value={value}
          onChange={e => {
            onChangeDate(e);
          }}
          required={required}
          error={error}
          helperText={errorMessage}
          key={key}
          label={!useTitleAsPlaceholder ? title : null}
          placeholder={useTitleAsPlaceholder ? title : placeholder}
          onKeyPress={onKeyPress}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          autoFocus={autoFocus}
          inputRef={inputRef}
          format="dd/MM/yyyy"
          InputProps={{
            style: {
              borderRadius: borderRadius
            }
          }}
          onClick={onClick}
        />
      </LocalizationProvider>
    );
  } else if (type === "time") {
    return (
      <LocalizationProvider utils={DateFnsUtils}>
        <TimePicker
          style={styles}
          inputVariant="outlined"
          value={value}
          onChange={e => {
            onChangeDate(e);
          }}
          required={required}
          error={error}
          helperText={errorMessage}
          key={key}
          label={!useTitleAsPlaceholder ? title : null}
          placeholder={useTitleAsPlaceholder ? title : placeholder}
          onKeyPress={onKeyPress}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          autoFocus={autoFocus}
          inputRef={inputRef}
          format="hh:mm"
          InputProps={{
            style: {
              borderRadius: borderRadius
            }
          }}
          onClick={onClick}
        />
      </LocalizationProvider>
    );
  } else {
    return (
      <TextField
        InputProps={{
          maxLength: maxLength,
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : null,
          endAdornment: endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : null,
          style: {
            borderRadius: borderRadius
          }
        }}
        style={styles}
        required={required}
        error={error}
        helperText={errorMessage}
        key={key}
        label={!useTitleAsPlaceholder ? title : null}
        placeholder={useTitleAsPlaceholder ? title : placeholder}
        variant="outlined"
        fullWidth
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        autoFocus={autoFocus}
        inputRef={inputRef}
        type={type}
        multiline={multiline}
        onClick={onClick}
      />
    );
  }
};

export default InputFieldOutlined;
