import React from "react";
import apiService from "../../services/apiService";
import "fabric-webpack";
import { useSearchParams } from "react-router-dom";

//service
import { postCertificate } from "../../services/download";

//Imports de components
import {
  Container,
  FormRow,
  FormColumn,
  Button,
  InputFieldOutlined,
  Dialog,
} from "../../components";
import FabricCanvasDesign from "./FabricCanvasDesign";
import FabricCanvasImage from "./FabricCanvasImage";
import FabricCanvasText from "./FabricCanvasText";

const UserCertificatePage = (props) => {
  const [state, setState] = React.useState(false);
  const [colorBackground, setColorBackground] = React.useState("transparent");
  const [buttonConfig, setButtonConfig] = React.useState({
    colorButton: "#3f51b5",
    colorTextButton: "white",
  });
  const [userEditName, setUserEditName] = React.useState();
  const [textConfig, setTextConfig] = React.useState({
    text: props.userName.substring(0, 45),
    left: props.namePositionWidth,
    top: props.namePositionHeigth,
    textColor: props.fontColor,
    textFontSize: props.fontSize,
    textFontType: "verdana",
    nameAlign: props.nameAlign,
  });
  const [imageConfig, setImageConfig] = React.useState({
    image: `${apiService.getUrl().corsAnywhere}${props.image}`,
  });
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [imageToDownload, setImageToDownload] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    getPageData();
    addListeners();
  }, []);

  const getPageData = () => {
    try {
      resizeCanvas();
    } catch (error) {
      console.log(error);
    }
  };

  const addListeners = () => {
    window.addEventListener("resize", resizeCanvas);
  };

  const resizeCanvas = () => {
    var _canvas = document.getElementById("certificate");
    if (_canvas && _canvas.style) {
      let _width = window.innerWidth * 0.9;
      _canvas.style.width = `${_width < 1000 ? _width : 1000}px`;
      _canvas.style.height = `${(_width < 1000 ? _width : 1000) * 0.55}px`;
      setState(!state);
    }
  };

  const downloadImageJpeg = async () => {
    let _base64 = document
      .getElementById("certificate")
      .toDataURL("image/jpeg");

    if (props.canvas.native) {
      // se tiver o paramêtro passado pelo app, baixa pelo app
      window.postMessage(
        JSON.stringify({
          message: "base64ToDownload",
          data: _base64,
          name: "Certificado",
        }),
        "*"
      );
      window.parent.ReactNativeWebView.postMessage(
        JSON.stringify({
          message: "base64ToDownload",
          data: _base64,
          name: "Certificado",
        })
      );
    } else {
      // se n tiver o parâmetro, baixa pelo web
      var link = document.createElement("a");
      link.download = "Certificado";
      link.target = "_blank";
      link.href = _base64;
      link.click();
    }

    await postCertificate(
      props.canvas.idEvent,
      props.canvas.idPart,
      props.canvas.idCertificate
    )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error));
  };

  const saveUserName = () => {
    var _textConfig = textConfig;
    _textConfig.text = userEditName;
    setTextConfig(_textConfig);
    setDialogOpen(false);
    setIsEditing(false);
  };

  return (
    <Container
      background={colorBackground}
      align="center"
      backgroundSize="100% 100%"
      height="100%"
    >
      <FormColumn align="center" background={colorBackground} margin="0px">
        <FormRow align="center" background="transparent" margin="0px">
          <div style={{ padding: 5 }}>
            <Button
              color={buttonConfig.colorButton}
              textColor={buttonConfig.colorTextButton}
              onClick={downloadImageJpeg}
            >
              Baixar
            </Button>
          </div>
        </FormRow>

        {!isEditing && (
          <FormRow
            align="center"
            background="transparent"
            margin="0px"
            paddingTop="5px"
          >
            <FabricCanvasDesign key="1">
              <FabricCanvasText
                text={textConfig.text}
                left={textConfig.left}
                top={textConfig.top}
                color={textConfig.textColor}
                fontSize={textConfig.textFontSize}
                fontType={textConfig.textFontType}
                nameAlign={textConfig.nameAlign}
              />
              <FabricCanvasImage url={imageConfig.image} scale={1} top={0} />
            </FabricCanvasDesign>
            <svg id="pdfSvg" xmlns="http://www.w3.org/2000/svg" />
          </FormRow>
        )}
      </FormColumn>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title="Nome"
        textButtonYes="Salvar"
        textButtonNo="Cancelar"
        onClickYes={saveUserName}
        onClickNo={() => {
          setDialogOpen(false);
          setIsEditing(false);
        }}
      >
        <FormRow align="center" paddingTop="5px">
          <InputFieldOutlined
            key="name"
            value={userEditName}
            onChange={(event) => setUserEditName(event.target.value)}
            maxLength={45}
          />
        </FormRow>
      </Dialog>
    </Container>
  );
};

export default UserCertificatePage;
