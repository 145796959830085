import React from "react";
import PropTypes from "prop-types";

const fabric = window.fabric;

class TextProp extends React.Component {
  static propTypes = {
    canvas: PropTypes.object,
    text: PropTypes.string.isRequired,
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    color: PropTypes.string,
    fontSize: PropTypes.number,
    fontType: PropTypes.string,
  };

  getConfig() {
    let _config;
    console.log("mah oe", this.props.nameAlign);
    if (this.props.nameAlign === "Esquerda") {
      _config = {
        left: this.props.left,
        top: this.props.top,
        fill: this.props.color || "black",
        fontFamily: this.props.fontType || "Arial",
        fontSize: this.props.fontSize || 15,
        selectable: false,
        evented: false,
        originX: "left",
      };
    } else if (this.props.nameAlign === "Direita") {
      _config = {
        left: this.props.left,
        top: this.props.top,
        fill: this.props.color || "black",
        fontFamily: this.props.fontType || "Arial",
        fontSize: this.props.fontSize || 15,
        selectable: false,
        evented: false,
        originX: "right",
      };
    } else {
      _config = {
        left: this.props.left,
        top: this.props.top,
        fill: this.props.color || "black",
        fontFamily: this.props.fontType || "Arial",
        fontSize: this.props.fontSize || 15,
        selectable: false,
        evented: false,
        originX: "center",
      };
    }

    return _config;
  }

  componentDidMount() {
    var text = new fabric.Text(this.props.text, {
      ...this.getConfig(),
    });
    this.props.canvas.add(text);
  }

  render() {
    return null;
  }
}

export default TextProp;
